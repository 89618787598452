import {Parallax} from "react-parallax";
import {Link} from "react-scroll";
import Navbar from '../layout/Navbar';
import Hero from '../section/hero';
import About from '../section/about';
import Blockquote from '../section/blockquote';
import Whatido from '../section/whatido';
import Blog from '../section/blog';
import Contact from '../section/contact';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from '../layout/ScrollToTop';
import Particle from '../layout/Particle';
import {createGlobalStyle} from 'styled-components';


const image1 = "./img/background/slider2.jpg";

const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit {
    display: block;
  }

  .navbar-brand .imgsaly {
    display: none !important;
  }
`;

function home() {
    return (
        <>
            <GlobalStyles/>
            <Preloader/>

            <div className="home">

                <header id="header-wrap">
                    <Navbar/>
                </header>
                {/* HERO */}
                <section id="hero-area" className="bg-bottom py-0">

                    <Parallax bgImage={image1} strength={300}>
                        <Particle/>
                        <Hero/>
                        <Link smooth spy to="about">
                          <span className="mouse transition" id="fly">
                              <span className="scroll"></span>
                          </span>
                        </Link>
                    </Parallax>
                </section>
                {/* ABOUT */}
                <section id="about" className="pb-0">
                    <About/>
                    <Blockquote/>
                </section>

                {/* What I DO */}
                <section id="whatido">
                    <Whatido/>
                </section>

                <section id="blog" className="pb-0">
                    <Blog/>
                </section>

                {/* contact */}
                <section id="contact" className="pb-0">
                    <Contact/>
                    <Footer/>
                </section>


                {/*<div className="float-text">*/}
                {/*    <div className="de_social-icons">*/}
                {/*        <span className="buton"><i className="fa fa-facebook fa-lg"></i></span>*/}
                {/*        <span className="buton"><i className="fa fa-twitter fa-lg"></i></span>*/}
                {/*        <span className="buton"><i className="fa fa-instagram fa-lg"></i></span>*/}
                {/*    </div>*/}
                {/*    <span>Follow Me</span>*/}
                {/*</div>*/}
            </div>
            <ScrollToTopBtn/>
        </>
    );
}

export default home;
